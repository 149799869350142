import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import axios from 'axios'
import vuecookies from 'vue-cookies'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.prototype.$cookie = vuecookies


Vue.filter("makeComma", val => {
	if(val){
		val = Math.ceil(val)
		return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}else{
		return 0
	}
})
Vue.filter("makePhoneNumber", val => {
	if(val){
		return val.substring(0, 3) + '-' + val.substring(3, 7) + '-' + val.substring(7, 11)
	}else{
		return ''
	}
})
Vue.filter("makeCardNumber", val => {
	if(val){
		return val.substring(0, 4) + '-' + val.substring(4, 6) + '##-####-' + val.substring(12, 16)
	}else{
		return ''
	}
})

Vue.filter("makeCardNumber2", val => {
	if(val){
		return val.substring(0, 4) + '-' + '****-****-' + val.substring(12, 16)
	}else{
		return ''
	}
})

Vue.filter("transDateWeek", val => {
	if(val){
			
		let weeks = ['일', '월', '화', '수', '목', '금', '토']
		let day = new Date(val.substring(0, 4) + '-' + val.substring(4, 6) + '-' + val.substring(6, 8)).getDay()
		console.log(day)
		let week = weeks[day]
		
		return val.substring(0, 4) + '년' + val.substring(4, 6) + '월' + val.substring(6, 8) + '일(' + week + ')' 
	}else{
		return ''
	}
})
Vue.filter("transDate", val => {
	if(val){
		return val.substring(0, 4) + '.' + val.substring(4, 6) + '.' + val.substring(6, 8)
	}else{
		return ''
	}
})
Vue.filter("transTime", val => {
	if(val){
		return val.substring(0, 2) + ':' + val.substring(2, 4) + ':' + val.substring(4, 6)
	}else{
		return ''
	}
})
Vue.filter("transWeek", val => {
	let weeks = ['일', '월', '화', '수', '목', '금', '토']
	if(val){
		let date = new Date(val.substring(0, 4) + '-' + val.substring(4, 6) + '-' + val.substring(6, 8))
		
		return weeks[date.getDay()]
	}else{
		return ''
	}
})

Vue.filter('makeTell', val => {
	if(val){
		val = val.replace('-', '')
		return val.substring(0, 3) + '-' + val.substring(val.length-8, val.length-4) + '-' + val.substring(val.length-4, val.length)
	}else{
		return ''
	}
})

Vue.filter('takeInitial', val => {
	if(val){
		const kor = ["ㄱ","ㄲ","ㄴ","ㄷ","ㄸ","ㄹ","ㅁ","ㅂ","ㅃ","ㅅ","ㅆ","ㅇ","ㅈ","ㅉ","ㅊ","ㅋ","ㅌ","ㅍ","ㅎ"];
		let result
		for(let i = 0 ; i < val.length; i++) {
			let utf = val.charCodeAt(i) - 44032;

			if(utf > -1 && utf < 11172) {
				result += kor[Math.floor(utf/588)];
			} else {
				result += val.charAt(i);
			}
			
			return result
		}
	}else{
		return ''
	}
})

new Vue({
  router,
  vuetify,
  vuecookies,
  render: h => h(App)
}).$mount('#app')
