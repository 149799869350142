
<template>		
		
	<v-alert 
		:type="type" 
		v-if="message" 
		style="position: fixed; width: 95%; bottom: 10%; right: 10px; z-index: 9999; font-size: 0.8em; padding: 2px;" 
	>
		{{ message }} 
		
	</v-alert>
	
</template>

<script>
	
	export default{
		name: 'alert-msg'
		,props: ['p_msg', 'p_type']
		,data: () => ({
			message: null
			,type: null
			
		})
		,watch: {
			p_msg: function(){
				this.alertMsg(this.p_type, this.p_msg)
			}
		}
		,methods: {
			closeMsg: function(){
				this.$emit('alertMsg', null, null);
			}
			,alertMsg: function(type, msg){
			
				this.type = type
				this.message = msg
  
				let timer = this.alertMsg.timer
				if (timer) {
					clearTimeout(timer)
				}
				
				this.alertMsg.timer = setTimeout(() => {
					this.type = null
					this.message = null
					this.closeMsg()
				}, 3000)

				this.elapse = 1 
				let t = this.alertMsg.t
				if (t) {
					clearInterval(t)
				}

				this.alertMsg.t = setInterval(() => {
					if (this.elapse === 3) {
						this.elapse = 0
						clearInterval(this.alertMsg.t)
					}
					else {
						this.elapse++
					}
				})
			}
		}
	}
</script>