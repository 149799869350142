<template>
	<div class="modal text-center">
		<v-dialog
			v-model="dialog"
			@click:outside="accept"
		>
			<v-card>
				<v-card-title 
					class="pl-3 subtitle-1 text-center">
					<v-icon 
						v-if="item.icon"
						:color="item.icon_color" 
						class="mr-1"
					>{{ item.icon }}</v-icon>
					{{ item.title | null }}
				</v-card-title>
				
				<v-divider
					v-if="item.contents_type == 'text' || !item.contents_type"
				></v-divider>

				<v-card-text
					v-if="item.contents"
					class="pa-0 contents-text"
				>
					
					<div
						v-if="item.contents_type == 'calendar'"
					>
						<div
							class="pa-1"
						>
						<v-text-field
							v-model="searchDates"
							label="조회 기간"
							placeholder="조회기간을 선택하세요"
							readonly
							outlined
							hide-details
							color="success2"
						>
						</v-text-field>
						</div>
						
						<v-date-picker 
							v-model="searchDates" 
							range
							class="pa-0 mt-1"
							full-width
							no-title
						></v-date-picker>
					</div>
					
					<v-simple-table
						v-if="item.contents_type == 'table'"
						class="border-top border-bottom-none"
					>
						<tbody>
							<tr
								v-for="(col, row) in item.contents"
								:key="row"
							>
								<th>{{ row }}</th>
								<td>{{ col }}</td>
							</tr>
						</tbody>
					</v-simple-table>
					
					<template
						v-if="item.contents_type == 'text' || !item.contents_type"
					>
						
						<div 
							class="pa-5 " 
							v-html="item.contents">
						
						</div>
					</template>
				</v-card-text>
				
				<v-card-text
					v-if="!item.contents"
				>
					
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						v-if="item.callBack"
						color="primary"
						@click="callBack"
						small
					>
						{{ item.callBack.name }}
					</v-btn>
					
					<v-btn
						color="success2"
						@click="accept"
						small
					>
						{{ item.accept | accept }}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	export default {
		name: 'Modal'
		,props: ['modal', 'options']
		,data () {
			return {
				dialog: false
				,item: {
				}
				,searchDates: [new Date().toISOString().substr(0, 10), new Date().toISOString().substr(0, 10)]
			}
		}
		,methods: {
			callModal: function(){
				console.log('callModal')
				this.dialog = true
			}
			,accept: function(){
				console.log('accept')
				this.$emit('removeModal')
				if(this.item.to){
					this.$router.push(this.item.to)
				}
			}
			,removeModal: function(){
				console.log('removeModal')
				this.dialog = false
			}
			,callBack: function(){
				if(this.item.contents_type == 'calendar'){
					this.$set(this.item.callBack, 'params', {dates: this.searchDates})
				}
				if(this.item.callBack.to){
					this.$router.push(this.item.callBack.to)
				}else if(this.item.callBack.do){
					this.$emit('callBack', this.item.callBack.do, this.item.callBack.params)
				}
				this.accept()
			}
		}
		,filters: {
			null: function(val){
				if(!val) return '알림'
				else return val
			}
			,accept: function(val){
				if(!val) return '확인'
				else return val
			}
		}
		,watch: {
			modal: {
				deep: true
				,handler: function(call){
					console.log('watch modal')
					if(call){
						this.callModal()
					}else{
						this.removeModal()
					}
				}
			}
			,options: {
				deep: true
				,handler: function(call){
					this.item = call
				}
			}
		}
	}
</script>
<style>
	.modal {}
	.modal .border-top {border-top: 1px solid #ddd}
	.modal .border-bottom-none {border-bottom: 0px solid #ddd}
	.contents-text {max-height: 450px; overflow: auto;}
</style>