<template>
	<v-app id="inspire">

		<v-app-bar 
			v-if="!program.not_title"
			app 
			color="white" 
		>
			<v-toolbar-title 
				class="text-center font-weight-bold text-subtitle-1" style="width: 100%; font-size: 1em;">
				
				<v-btn
					v-if="program.prev && program.prev.to"
					text
					class="pa-0"
					style="font-size: 1.5em; font-weight: normal; min-width: 0; width: auto; position: absolute; left: 10px; top: 9px;"
					:to="program.prev.to"
				>
					<v-icon
					>mdi-arrow-left-circle</v-icon>
				</v-btn>
				
				<v-btn
					v-if="program.prev && !program.prev.to"
					text
					class="pa-0"
					style="font-size: 1.5em; font-weight: normal; min-width: 0; width: auto; position: absolute; left: 10px; top: 9px;"
					@click="doCallBack(program.prev.callBack)"
				>
					<v-icon
					>mdi-arrow-left-circle</v-icon>
				</v-btn>
				
				<v-btn
					v-if="!program.prev"
					text
					class="pa-0"
					style="font-size: 1.5em; font-weight: normal; min-width: 0; width: auto; position: absolute; left: 10px; top: 9px;"
					@click="$router.go(-1)"
				>
					<v-icon
					>mdi-arrow-left-circle</v-icon>
				</v-btn>
				
				{{ program.title }}
				
				<template
					v-if="program.next"
				>
					<v-btn
						v-if="program.next.callBack"
						@click="doCallBack(program.next.callBack)"
						style="position: absolute; right: 10px; top: 13px;"
						
						small
						class="success2"
						:disabled="program.next.do ? false : true"
					>
						{{ program.next.title }}
					</v-btn>
					
					<v-btn
						v-if="!program.next.callBack"
						:to="program.next.url"
						style="position: absolute; right: 10px; top: 13px;"
						
						small
						class="success2"
						:disabled="program.next.do ? false : true"
					>
						{{ program.next.title }}
					</v-btn>
				</template>
			</v-toolbar-title>
		</v-app-bar>

		<v-main
		>
			<router-view 
				@axios="axios"	
				@alertMsg="alertMsg"
				@setProgram="setProgram"
				@modal="modal"
				@logout="logout"
				@onfirm="confirm"
				@setUser="setUser"
				@getSearch="getSearch"
				@refreshToken="refreshToken"
				
				:TOKEN="TOKEN"
				:callBack="callBack"
				:authes="authes"
				:program="program"
				:options="options"
				:user="user"
			></router-view>
			
			<alert-msg 
				@alertMsg="alertMsg"
				
				:p_msg="message" 
				:p_type="type"
			></alert-msg>
			
		</v-main>
		
		<ScrollTop></ScrollTop>
		
		<Navigation
			v-if="!program.not_navigation"
		>
		</Navigation>
			
		<modal
			:modal="modalUp"
			:options="modalOption"
			
			@removeModal="removeModal"
			@callBack="doCallBack"
		>
		</modal>
	</v-app>
	
</template>

<script>

	import AlertMsg from '@/components/AlertMsg'
	import Modal from '@/components/Modal'
	import Navigation from '@/components/Navigation'
	import ScrollTop from '@/components/ScrollTop'

	import { Base64 } from 'js-base64'

	export default {
	
		name: 'App'
		,created(){
			this.isToken()			// 토큰 체크
		}
		,components: { AlertMsg, Modal, Navigation, ScrollTop}
		,data: function(){
			return {
				TOKEN: ''				//API용 토큰
				,drawer: ''				//vuetify 레이어명
				,message: ''			//alertMsg 알림 메시지
				,type: ''				//alertMsg 알림 타입 (ERROR, SUCCESS)
				,token_limit: 60 * 60	//쿠키 라이프 타임
				,auth_config: {
				}						//권한 
				,callBackConfig: {
					//request_type: 	REST API 요청 타입 (GET, POST, PUT, DELETE)
					//request_url: 		API 요청 경로
					//request_params: 	API 요청 파라미터 object
					//callBack: 		API 응답 처리용 콜백 함수
					//callBackError: 	API 응답오류 및 통신오류시 에러처리용 콜백 함수
					//callParent:		최상위 처리 함수
					//orgin_url: 		커스텀 외부 경로
				}
				,callBack: {
					//name: 			콜백 함수명
					//items: 			콜백 파라미터 object
				}
				,callParent:{
				}
				,authes: {
				}
				,request_url_default: 'https://api-dev-pg.reappay.net/api/v1'
				,request_url_real: 'https://api-pg.reappay.net/api/v1'
				,request_url_local: 'http://localhost:9081/api/v1'
				,program: {
				}
				,options: [
				]
				,user_info: {
				}
				,is_used: []
				,modalUp: false
				,modalOption: {}
				,user: {}
				,codes: {}
			}
		}
		,methods: {
			// TOKEN 검증
			isToken: function(){
				if(!localStorage.getItem('paylinkt')){
//					this.$router.push('/login')
				}else{
					this.setUser(localStorage.getItem('paylinkt'))
				}
//
			}
			,refreshToken: async function(){
				this.axios({
					request_type: 'post'
					,request_url: '/auth/refresh-token'
					,request_params: {
						refreshToken: localStorage.getItem('paylinkr')
					}
					,authorizse: true
					,callBack: 'setRefreshToken'
					,not_error: true
				})
			}
			,setRefreshToken: function(call){

				if(call.result){
					localStorage.setItem('paylinkt', call.items.content.access_token)
					localStorage.setItem('paylinkr', call.items.content.refresh_token)
				}else{
					this.alertMsg('error', '토큰 검증 오류')
				}
			}
			,getSearch: function(option){
				this.modal(option)
			}
			,authority: function(menu_code, auth_code){
				console.log('App authority')
				this.$set(this.auth_config, 'ADMIN_CODE', this.user_info.ADMIN_CODE)
				this.$set(this.auth_config, 'MENU_CODE', menu_code)
				this.$set(this.auth_config, 'AUTH_CODE', auth_code)
			}
			,clearAuth: function(){
				//console.log('clearAuth')
				this.$set(this.auth_config, 'ADMIN_CODE', null)
				this.$set(this.auth_config, 'MENU_CODE', null)
				this.$set(this.auth_config, 'AUTH_CODE', null)
			}
			,setAuth: function(items){
				this.authes = items
			}
			// 모달
			,modal: function(option){
				console.log('App modal')
				this.modalUp = true
				this.modalOption = option
			}
			,removeModal: function(){
				console.log('App removeModal')
				this.modalUp = false
				this.modalOption = {title: ''}
			}
			,confirm: function(option){
				if(confirm(option.title)){
					this.$router.push(option.to)
				}
			}
			// 알림 메시지 - alertMsg 컴포넌트 호출
			,alertMsg: function(type, msg){
				console.log('app alertMsg ' + this.program.name + ' : ' + type + ' : ' + msg)
				this.type = type
				this.message = msg
			}
			,logout: function(){
				if(confirm("로그아우시 PIN로그인도 같이 해제됩니다\r\n PIN 재설정시 PIN로그인으로 자동 설정됩니다.\r\n 로그아웃 하시겠습니까?")){
					this.TOKEN = null
					localStorage.removeItem('paylinkt')
					localStorage.removeItem('paylinkp')
					localStorage.removeItem('paylinkc')

					try{
						const NUA = navigator.userAgent.toLowerCase()
						
						if(NUA.indexOf('android') > -1){
							window.Android.setPinCode('')
							window.Android.setPinCodeUse('N')
						}
					}catch(error){
						this.alertMsg('error', 'pin 해제 오류')
						this.alertMsg('error', error)
					}
					
					this.user = {}
					this.$router.push('/')
				}
			}
			,setUser: function(access_token){

				const excepts = ['login', 'findid', 'findpw', 'branch/signup', 'payment/sms', 'signup']
				let except = true
				let path = this.$route.path.toLowerCase()
				excepts.forEach(function(val){

					if(path.indexOf(val) > -1){
						except = false
					}
				})

				if(except){
					this.axios({
						request_type: 'get'
						,request_url: '/users/main/me'
						,request_header: {
							Authorization: 'Bearer ' + access_token
						}
						,callBack: 'setUserResult'
						,not_error: true
					})
				}
			}
			,setUserResult: function(call){
				if(call.result){
					this.$set(this, 'user', call.items.content)
					this.$set(this.user, 'userId', Base64.decode(Base64.decode(localStorage.getItem('paylinkn'))))
				}else{
					const excepts = ['login', 'findid', 'findpw', 'branch/signup', 'payment/sms']
					let except = false
					let path = this.$route.path.toLowerCase()
					excepts.forEach(function(val){
						
						if(path.indexOf(val) > -1){
							except = true
							return
						}
					})
					if(!except){
						this.$router.push('/login')
					}
				}
			}
			// API 통신용 공통 axios
			,axios: function(params){
				
				try{
					this.callBackConfig = params
					
					const self = this
					const call = this.callBackConfig
					
					if(this.checkRequest(call)){
						return false
					}
					
					let result = false
					let headers = {
						'Cache-Control': 'no-cache'
						, 'Pragma': 'no-cache'
					}
					let timeout = 5000
					
					if(call.authorize){
						const access_token = localStorage.getItem('paylinkt')
						this.$set(headers, 'Authorization', 'Bearer ' + access_token)
					}
					
					if(call.multiform){
						this.$set(headers, 'Content-Type', 'multipart/form-data')
					}
					
					if(call.request_header){
						headers = call.request_header
					}
					
					if(call.dev){
						
						if(call.dev == 'success'){
							self.callBack = {
								name: call.callBack
								,result: true
							}
						}else{
							self.callBack = {
								name: call.callBack
								,result: false
							}
						}
						
						self.resetRequest(call)
						
					}else{
						let method = params.request_type
						
						let url = ''
						
						if(call.origin_url){
							url = call.origin_url
						}else{
						
							if(document.location.hostname == 'paylinkkorea.com'){
								url = this.request_url_real + call.request_url
							}else if(document.location.hostname == 'localhost'){
								url = this.request_url_default + call.request_url
								//url = this.request_url_local + call.request_url
							}else{
								url = this.request_url_default + call.request_url
							}
								
						}
						let config = {
							method: method
							,url: url
							,headers: headers
							,timeout: timeout
						}
						
						if(method == 'get'){
							config.params = call.request_params
						}else{
							if(call.formData){
								const formData = new FormData();
								for(let key in call.request_params){
									formData.append(key, call.request_params[key])
								}
								config.data = formData
							}else{
								config.data = call.request_params
							}
						}
						
						this.$axios(config)
						.then(function(response) {
							
							if(response.data.status < 300){
								result = true
							}else{
								result = false
							}
							
							if(call.callParent){
								self.callParent = {
									name: call.callParent
									,items: response.data
									,result: result
								}
							}else if(call.callBack){
								self.callBack = {
									name: call.callBack
									,items: response.data
									,result: result
								}
							}
							
							if(result && call.message){
								if(result){
									self.alertMsg('success', response.data.message)
								}
							}

							if(!result){
								if(call.error_message){
									if(!call.not_error){
										self.alertMsg('error', response.data.message)
									}
								}else{
									self.alertMsg('error', response.data.message)
								}
							}
						})
						.catch(function(error) {
							console.log(params.request_type + ' catch error')
							console.log(error)
							self.$set(self.program, 'error', true)
							
							self.callBack = {
								name: call.callBack
								,items: error.data
								,result: false
							}
							
							if(!call.not_error){
								self.alertMsg('error', '통신 오류!')
							}
						})
						.then(function(){
							self.resetRequest(call)
						});
					}
				}catch(error){
					this.alertMsg('error', error)
				}
			}
			,checkRequest: function(call){
				
				if(!call.stack){
					if(this.is_used[call.callBack]){
						this.alertMsg('error', call.callBack + ' 요청 처리중입니다')
						return true
					}else{
						this.$set(this.is_used, call.callBack, true)
						return false
					}
				}else{
					return false
				}
			}
			,setProgram: function(program){
				this.$set(this, 'program', program)
			}
			,resetRequest: function(call){
				this.$set(this.is_used, call.callBack, false)
			}
			,doCallBack: function(call, params){
				this.callBack = {
					name: call
					,params: params
				}
			}
		}
		,computed: {
			tokenCheck: function(){
				var token = localStorage.getItem('paylinkt')
				return token
			}
		}
		,program: {
			deep: true
			,handler: function(call){
				console.log('watch program')
				console.log(call)
			}
		}
		,watch:{
			tokenCheck:{
				handler: 'isToken'
			}
			,TOKEN: {
				handler: 'isToken'
			}
			,callParent: {
				deep: true
				,handler: function(call){
					console.log(call)
				}
			}
			,callBack: {
				deep: true
				,handler: function(call){
					if(call.name == 'setUserResult'){
						this.setUserResult(call)
					}else if(call.name == 'setRefreshToken'){
						this.setRefreshToken(call)
					}
					console.log(call)
					//
				}
			}
		}
	}
	
	
</script>


<style>

	.v-text-field input{
		font-size: 1em;
	}

	.v-label {

	}

	.v-text-field.v-text-field--solo .v-input__control{
		min-height: auto;
	}

	.v-list-item__content{
		padding: 10px;
	}

	.v-list-item {
		min-height: 24px;
	}

	.v-list-item__title{
		font-size: 1em;
	}

	.v-select__selection {
		font-size: 1em;
		min-height: 30px;
	}
  
	.v-expansion-panels {
		z-index: 0 !important;
	}

	.v-expansion-panel-header{
		padding: 5px !important;
	}

	.v-expansion-panel-content__wrap{
		padding: 0 !important;
	}

	td {text-align: center;}
	
	.v-application .green2
	,.v-application .success2 {background-color: #00BFA5 !important; color: white !important;}
	.v-application .success2--text {
		color: #00BFA5 !important; 
		caret-color: #00BFA5 !important;
	}
	
	.bg {background-color: #15324E; color: white;}
	.bg-white {background-color: white;}
	.bg-color {background-color: #F3FDFC;}
	.bg-eee {background-color: #eee}
	.bg-eo {background-color: #e0e0e0}
	.bg-bd {background-color: #bdbdbd}
	.bg-9e {background-color: #9e9e9e}
	.bg-yellow {background-color: #FFFDE7 !important;}
	.bg-success2 {background-color: #00bfa5}
	
	.mb-50 {margin-bottom: 150px;}
	
	.under-line1 {border-bottom: 1px solid #ddd;}
	.under-line3 {border-bottom: 3px solid #00BFA5;}
	
	.text-color {color: #00BFA5;}
	.text-gray {color: gray;}
	.text-black {color: black;}
	.text-white {color: white !important;}
	
	.line-right {border-right: 1px solid #ddd;}
	
	.line-dash {border: dashed !important; border-width: thin !important; border-color: gray !important;}
	
	.border {border: 1px solid #ddd;}
	.border-bottom {border-bottom: 1px solid #424242;}
	.border-weight-2 {border-width: 2px;}
	
	.v-application .yellow2 {background-color: #FDD835 !important}
	
	.mb-150 {margin-bottom: 150px;}
</style>
