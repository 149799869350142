import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Intro',
		component: () => import('../views/Login.vue')
	}
	,{
		path: '/home',
		name: 'Home',
		component: () => import('../views/Home.vue')
	}
	,{
		path: '/login'
		,name: 'Login'
		,component: () => import('../views/Login.vue')
		//,meta: { unauthorized: true}
	}
	,{
		path: '/pin'
		,name: 'Pin'
		,component: () => import('../views/Pin.vue')
		//,meta: { unauthorized: true}
	}
	,{
		path: '/help/personal'
		,name: 'Personal'
		,component: () => import('../views/Help/Personal.vue')
		//,meta: { unauthorized: true}
	}
	,{
		path: '/help/findid'
		,name: 'FindId'
		,component: () => import('../views/Help/FindId.vue')
		//,meta: { unauthorized: true}
	}
	,{
		path: '/help/findpw'
		,name: 'FindPw'
		,component: () => import('../views/Help/FindPw.vue')
		//,meta: { unauthorized: true}
	}
	,{
		path: '/Help/Agree'
		,name: 'Agree'
		,component: () => import('../views/SignUp/SignUpService.vue')
		//,meta: { unauthorized: true}
	}
	,{
		path: '/Help/Account'
		,name: 'Account'
		,component: () => import('../views/Help/SignAccount.vue')
		//,meta: { unauthorized: true}
	}
	,{
		path: '/Help/Making'
		,name: 'Account'
		,component: () => import('../views/Help/Making.vue')
		//,meta: { unauthorized: true}
	}
	,{
		path: '/SignUp'
		,name: 'SignUp'
		,component: () => import('../views/SignUp/SignUp.vue')
		//,meta: { unauthorized: true}
	}
	,{
		path: '/SignUp/SignUpAgency'
		,name: 'Agency'
		,component: () => import('../views/SignUp/SignUpAgency.vue')
		//,meta: { unauthorized: true}
	}
	,{
		path: '/SignUp/SignService'
		,name: 'SignService'
		,component: () => import('../views/SignUp/SignUpService.vue')
		//,meta: { unauthorized: true}
	}
	,{
		path: '/SignUp/signup/result'
		,name: 'SignUpResult'
		,component: () => import('../views/SignUp/SignUpResult.vue')
		//,meta: { unauthorized: true}
	}
	,{
		path: '/Payment/Sms/:authorize/:index'
		,name: 'PaymentSms'
		,component: () => import('../views/Payment/sms/payment.sms.vue')
	}
	,{
		path: '/payment/List'
		,name: 'PaymentList'
		,component: () => import('../views/Payment/List.vue')
	}
	,{
		path: '/payment/List/:sDate?/:eDate?'
		,name: 'PaymentList'
		,component: () => import('../views/Payment/List.vue')
	}
	,{
		path: '/payment/Item'
		,name: 'PaymentRegist'
		,component: () => import('../views/Payment/Regist.vue')
	}
	,{
		path: '/payment/Item/:item_id'
		,name: 'PaymentDetail'
		,component: () => import('../views/Payment/Detail.vue')
	}
	,{
		path: '/payment/Receipt/:item_id'
		,name: 'PaymentReceipt'
		,component: () => import('../views/Payment/Receipt.vue')
	}
	,{
		path: '/Payment/Sms/Receipt/:authorize/:index/:tranSeq',
		name:'PaymentSmsReceipt',
		component: () => import('../views/Payment/sms/payment.sms.receipt.vue')
	}
	,{
		path: '/colculate'
		,name: 'ColculateList'
		,component: () => import('../views/Colculate/List.vue')
	}
	,{
		path: '/colculate/Item/:item_id'
		,name: 'PaymentRegist'
		,component: () => import('../views/Colculate/Detail.vue')
	}
	,{
		path: '/mypage'
		,name: 'Mypage'
		,component: () => import('../views/User/Mypage.vue')
		//,meta: { unauthorized: true}
	}
	,{
		path: '/User/Profile'
		,name: 'Profile'
		,component: () => import('../views/User/Profile.vue')
	}
	,{
		path: '/User/Pin'
		,name: 'MypagePin'
		,component: () => import('../views/User/Pin.vue')
		//,meta: { unauthorized: true}
	}
	,{
		path: '/User/Alram'
		,name: 'MYpageAlram'
		,component: () => import('../views/User/Alram.vue')
	}
	,{
		path: '/User/Setting'
		,name: 'MYpageSetting'
		,component: () => import('../views/User/Setting.vue')
	}
	,{
		path: '/User/Password'
		,name: 'MypagePassword'
		,component: () => import('../views/User/Password.vue')
		//,meta: { unauthorized: true}
	}
	,{
		path: '/Branch/'
		,name: 'Branch'
		,component: () => import('../views/Branch/List.vue')
		//,meta: { unauthorized: true}
	}
	,{
		path: '/Branch/Home'
		,name: 'BranchHome'
		,component: () => import('../views/Branch/Home.vue')
		//,meta: { unauthorized: true}
	}
	,{
		path: '/Branch/Choice'
		,name: 'BranchChoice'
		,component: () => import('../views/Branch/Choice.vue')
		//,meta: { unauthorized: true}
	}
	,{
		path: '/Branch/Friends'
		,name: 'Branch'
		,component: () => import('../views/Branch/Friends.vue')
		//,meta: { unauthorized: true}
	}
	,{
		path: '/Branch/Guest'
		,name: 'Branch'
		,component: () => import('../views/Branch/Guest.vue')
		//,meta: { unauthorized: true}
	}
	,{
		path: '/Branch/Item'
		,name: 'BranchItem'
		,component: () => import('../views/Branch/Regist.vue')
		//,meta: { unauthorized: true}
	}
	,{
		path: '/Branch/SignUp/:authorize'
		,name: 'BranchSignUp'
		,component: () => import('../views/Branch/SignUp.vue')
	}
	,{
		path: '/Branch/Result'
		,name: 'BranchResult'
		,component: () => import('../views/Branch/Result.vue')
	}
	,{
		path: '/Branch/Unknown'
		,name: 'BranchUnkonwn'
		,component: () => import('../views/Branch/Unknown.vue')
	}
	,{
		path: '/Branch/Payment'
		,name: 'BranchPayment'
		,component: () => import('../views/Branch/Payment.vue')
	}
	,{
		path: '/Branch/Payment/Daily/:salesSeq/:paymentDate'
		,name: 'BranchPayment'
		,component: () => import('../views/Branch/PaymentDaily.vue')
	}
	,{
		path: '/Branch/Payment/Weekly'
		,name: 'BranchPaymentWeekly'
		,component: () => import('../views/Branch/PaymentWeekly.vue')
	}
	,{
		path: '*',
		name:'404', 
		component: () => import('../views/Error/NotFound.vue')
	}
	,{
		path: '/Test'
		,name: 'Test'
		,component: () => import('../views/Test.vue')
	}
	,{
		path: '/Error/Network',
		name:'Network', 
		component: () => import('../views/Error/Network.vue')
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

router.beforeEach((to, from, next) => {
	next();
});

export default router
