<template>
	<v-btn
		v-scroll="onScroll"
		v-show="fab"
		fab
		dark
		fixed
		bottom
		right
		color="primary"
		small
		@click="toTop"
	>
		<v-icon>keyboard_arrow_up</v-icon>
	</v-btn>
</template>

<script>
	export default{
		name: 'ScrollTop'
		,props:[]
		,data: function(){
			return {
				fab: false
			}
		}
		,methods: {
			onScroll (e) {
				if (typeof window === 'undefined') return
				const top = window.pageYOffset ||   e.target.scrollTop || 0
				this.fab = top > 20
			},
			toTop () {
				this.$vuetify.goTo(0)
			}
		}
	}
</script>