<template>
	<v-bottom-navigation
		color="success2"
		fixed
	>
		<v-btn
			v-for="(item, index) in items"
			:key="index"
			:to="item.url"
		>
			<span>{{ item.label }}</span>

			<v-icon>{{ item.icon }}</v-icon>
		</v-btn>

	</v-bottom-navigation>
</template>

<script>
	export default{
		name: 'Navigation'
		,props: ['program']
		,data: function(){
			return{
				items: [
					{
						url: '/Home'
						,label: '홈'
						,icon: 'mdi-home-outline'
					}
					,{
						url: '/Payment/Item'
						,label: '수기결제'
						,icon: 'mdi-pencil-outline'
					}
					,{
						url: '/Payment/List'
						,label: '결제현황'
						,icon: 'mdi-cash-multiple'
					}
					,{
						url: '/Colculate'
						,label: '정산현황'
						,icon: 'mdi-calculator'
					}
				]
			}
		}
	}
</script>

<style>
	
</style>